import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
// import Divider from '../../components/Divider'

import PreVerifiedIcon from '../../assets/Business/Auth & Onboarding/Icons/preverified.svg'
import PreAuthIcon from '../../assets/Business/Auth & Onboarding/Icons/preauth.svg'
import PasswordlessIcon from '../../assets/Business/Auth & Onboarding/Icons/passwordless.svg'
import ReusableIcon from '../../assets/Business/Auth & Onboarding/Icons/reusable.svg'
import DataExchangeIcon from '../../assets/Business/Auth & Onboarding/Icons/data_exchange.svg'
import AnonymousIcon from '../../assets/Business/Auth & Onboarding/Icons/anonymous.svg'

import RiskReductionIcon from '../../assets/Business/Auth & Onboarding/Icons/risk_reduction.svg'
import CustomerAcquisitionIcon from '../../assets/Business/Auth & Onboarding/Icons/customer_acquisition.svg'
import OnboardingIcon from '../../assets/Business/Auth & Onboarding/Icons/onboarding.svg'
import FraudIcon from '../../assets/Business/Auth & Onboarding/Icons/fraud.svg'
import EfficiencyIcon from '../../assets/Business/Auth & Onboarding/Icons/efficiency.svg'
import AuditIcon from '../../assets/Business/Auth & Onboarding/Icons/audit.svg'

import CTABox from '../../components/CTABox'
import Section from '../../components/Section'
import SectionTitleBlock from '../../components/SectionTitleBlock'
import FloatIconFeature from '../../components/FloatIconFeature'
import CTALink from '../../components/CTALink'
import routes from '../../routes'
import ContactUs from '../../components/ContactUs'

const HeroImage = '../../assets/Home/Images/onboarding4.png'

const IntegrationImg =
  '../../assets/Business/Auth & Onboarding/Images/integration2.png'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    textAlign: 'center',
    marginBottom: 15,
    maxWidth: 280,
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    textAlign: 'center',
    maxWidth: 350,
    color: 'rgba(115, 115, 115, 1)',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },
  /**
   * || Hero Section
   * ======================================= */
  heroWrapper: {
    position: 'relative',
    top: 0, // Account for the header bar height
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    // paddingBottom: 100,

    [theme.breakpoints.up('desktop')]: {
      // paddingBottom: 100,
    },
    [theme.breakpoints.up('desktopXL')]: {
      // paddingBottom: 250,
    },
  },
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 96,
    marginBottom: 100,
    overflow: 'hidden',
    width: 1600,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    maxWidth: theme.custom.maxWidth,

    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'left',
      paddingTop: 143,
    },

    [theme.breakpoints.up('desktop')]: {
      paddingTop: 154,
      top: 20,
    },

    // '& .gatsby-image-wrapper': {
    //   display: 'none !important',

    //   [theme.breakpoints.up('tablet')]: {
    //     display: 'block !important',
    //     height: 'auto',
    //     position: 'relative',
    //     width: '40%',
    //     maxWidth: '230px',
    //   },

    //   [theme.breakpoints.up('desktop')]: {
    //     maxWidth: '300px',
    //   },

    //   [theme.breakpoints.up('desktopXL')]: {},
    // },
  },
  heroImage: {
    display: 'none !important',

    // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    // borderRadius: 10,
    [theme.breakpoints.up('tablet')]: {
      // top: '60%', // 'calc(82% - 80px)',
      display: 'block !important',
      position: 'absolute !important',
      right: 'calc(-215px + 5%)',
      width: 'calc(200px + 45%)',
    },

    [theme.breakpoints.up('desktop')]: {
      width: 'calc(200px + 45%)',
      height: 'auto',
      marginRight: 50,
      right: 'calc(-265px + 5%)',
      top: '60%', // 'calc(82% - 80px)',

      transform: 'translate(0%, -50%)',
    },

    [theme.breakpoints.up('1100')]: {
      left: '50%',
      width: 800,
    },
    [theme.breakpoints.up('1400')]: {
      width: 900,
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('tablet')]: {
      // padding: 28,
      width: '60%',
    },

    [theme.breakpoints.up('desktop')]: {},
  },
  heroTitle: {
    textAlign: 'left',
    fontSize: '32px',
    color: 'black',

    [theme.breakpoints.up('tablet')]: {
      fontSize: '44px',
      width: 380,
    },

    [theme.breakpoints.up('desktop')]: {
      width: '100%',
      fontSize: '56px',
    },
  },
  heroDescription: {
    maxWidth: 340,
    fontSize: 16,
    lineHeight: '160%',
    margin: '20px 0px',

    color: 'rgba(18, 17, 39, 0.56)',

    textAlign: 'left',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 270,
    },

    [theme.breakpoints.up('800')]: {
      maxWidth: 370,
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 18,
      maxWidth: 502,

      textAlign: 'left',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },
  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      marginRight: 10,
      fontSize: '12px',

      padding: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '110px',
    },

    [theme.breakpoints.up('800')]: {
      width: '140px',
      fontSize: '14px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      width: '170px',
    },
  },

  /**
   * || Authentication Section
   * ======================================= */
  authenticationSection: {
    marginTop: 50,
    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
    },
  },
  authenticationGrid: {
    display: 'grid',

    margin: 'auto',
    marginTop: 50,

    [theme.breakpoints.up('desktop')]: {
      justifyItems: 'center',
      gridTemplate: '1fr 1fr / 1fr 1fr 1fr',

      rowGap: 30,
      columnGap: 30,
      width: '100%',

      maxWidth: 1150,
    },
  },
  authenticationGridItem: {
    marginTop: 25,
    maxWidth: 350,

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 275,
    },

    '& svg': {
      width: 35,
      height: 'auto',

      color: '#2FA43B',
    },

    '& h3': {
      marginTop: 5,
      marginBottom: 10,
    },
  },

  /**
   * || Integration Section
   * ======================================= */
  integrationSection: {
    marginTop: 50,
    paddingBottom: 50,
    paddingTop: 50,
    maxWidth: theme.custom.maxWidth,
    margin: 'auto',

    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('800')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,
      paddingBottom: 100,
      paddingTop: 100,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 150,
    },
  },
  integrationImage: {
    maxWidth: 450,
    width: '100%',

    [theme.breakpoints.up('desktop')]: {
      width: '45%',
      maxWidth: 550,
    },
  },

  integrationColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    marginBottom: 50,

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',

      maxWidth: 550,

      textAlign: 'left',
    },
  },

  /**
   * || Advantages Section
   * ======================================= */
  advantagesSection: {
    marginTop: 75,

    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      marginTop: 150,
      marginBottom: 130,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 180,
    },
  },
  advantagesGrid: {
    display: 'grid',

    margin: 'auto',

    [theme.breakpoints.up('desktop')]: {
      gridTemplate: '1fr 1fr / 1fr 1fr 1fr',
      justifyItems: 'center',

      rowGap: 30,
      columnGap: 40,
      width: '100%',
      maxWidth: 1350,
    },
  },
  advantagesGridItem: {
    marginTop: 25,
    maxWidth: 350,

    '& svg': {
      width: 35,
      height: 'auto',

      color: '#2FA43B',
    },

    '& h3': {
      marginTop: 5,
      marginBottom: 10,
    },
  },
  ctaBox: {
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.up('tablet')]: {
      marginTop: 120,
      marginBottom: 120,
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 190,
      marginBottom: 190,
    },
  },
}))

const authenticationItems = [
  {
    title: 'Pre-Verified',
    description:
      "User IDs are verified in CycurID's back end before they come to you, so your company is saved the hassle of running verification checks.",
    icon: <PreVerifiedIcon />,
  },
  {
    title: 'Pre-Authenticated',
    description:
      "CycurID's user App (Imme®) authenticates ID tokens as they access your system.",
    icon: <PreAuthIcon />,
  },
  {
    title: 'Passwordless',
    description:
      'IDs are encrypted under biometric security, removing the need for your business to store or manage login credential data.',
    icon: <PasswordlessIcon />,
  },
  {
    title: 'Lifelong & Reusable',
    description:
      "There's no point in your company running the same checks to verify users if someone else has already. CycurID does this once, allowing users to be verified for life.",
    icon: <ReusableIcon />,
  },
  {
    title: 'Data Exchange-Free',
    description:
      "Customers don't have to submit login data for your business to store, keeping volatile and sensitive data off of your servers.",
    icon: <DataExchangeIcon />,
  },
  {
    title: 'Anonymized',
    description: `"Anonymous" doesn't have to mean "unknown." IDs are fully verified and authenticated through Zero-Knowledge protocols.`,
    icon: <AnonymousIcon />,
  },
]

const advantagesItems = [
  {
    title: 'Risk Reduction',
    description:
      "Zero-Knowledge onboarding and authentication means you don't have to store customer data, removing a potential attack vector for cybercriminals.",
    icon: <RiskReductionIcon />,
  },
  {
    title: 'Focused Customer Acquisition',
    description:
      "Don't waste your marketing dollars on users that won't meet your onboarding criteria. Screen potential customers before they try to onboard with continuous KYC.",
    icon: <CustomerAcquisitionIcon />,
  },
  {
    title: 'Instant Onboarding',
    description:
      'Onboard new users instantly with pre-verified ID tokens. Avoid abandonment and improve user experience by removing redundant information exchanges.',
    icon: <OnboardingIcon />,
  },
  {
    title: 'Fraud Elimination',
    description:
      "Fraudulent access and stolen credentials are big risks to digital business. Keep fraudsters out with CycurID's biometric authentication and liveness detection.",
    icon: <FraudIcon />,
  },
  {
    title: 'Efficient Allocation',
    description:
      'Without needing to verify, authenticate, or screen as part of the onboarding process, your business is free to allocate your resources to growing your bottom line.',
    icon: <EfficiencyIcon />,
  },
  {
    title: 'Audited AI & AI Auditing',
    description: `Our AI automatically audits all potential users to help you stay compliant, and we continuously audit our AI to ensure you're getting industry-leading service.`,
    icon: <AuditIcon />,
  },
]
export default function AuthOnboarding() {
  const classes = useStyles()
  return (
    <main>
      <section className={classes.heroWrapper}>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            {/* <Divider style={{ marginBottom: 12 }} /> */}
            <Typography variant='h1' className={classes.heroTitle}>
              Truly Frictionless <br /> Onboarding with <br />{' '}
              <span style={{ color: 'rgba(47, 164, 59, 1)' }}>
                Digital Identity
              </span>
            </Typography>

            <Typography variant='body1' className={classes.heroDescription}>
              Getting customers in the door is the critical first step in doing
              business, but you have to make sure everyone stepping through that
              door is who they say they are without driving them away.{'\n\n'}
              CycurID leverages the power of Digital Identity to give a truly
              frictionless onboarding and authentication experience. We
              don&apos;t just expedite your existing processes, we handle the
              whole process for you making it instantaneous without sacrificing
              security.
            </Typography>
            <div className={classes.buttonContainer}>
              <CTALink
                className={classes.signUpButton}
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
              >
                Get Started
              </CTALink>
              <CTALink
                className={classes.signUpButton}
                href={routes.CONTACT}
                variant='outline'
              >
                Talk To Sales
              </CTALink>
            </div>
          </div>
          <StaticImage
            src={HeroImage}
            alt='CycurID dashboard onboarding rules page screenshot'
            className={classes.heroImage}
            loading='eager'
            quality={100}
          />
        </div>
      </section>

      <Section className={classes.authenticationSection}>
        <SectionTitleBlock
          title='The Future of Authentication'
          description={
            "The core of CycurID's solution to authentication and onboarding is the power of a Reusable Digital Identity Token. Users register with CycurID to create a Reusable Encrypted Zero-Knowledge Identity Token that they present to your company's onboarding or authentication platform, letting them seamlessly and instantly connect."
          }
          bar='true'
        />

        <div className={classes.authenticationGrid}>
          {authenticationItems.map((item) => (
            <div className={classes.authenticationGridItem} key={item.title}>
              {item.icon}
              <Typography variant='h3'>{item.title}</Typography>
              <Typography variant='body2' style={{ color: '#737373' }}>
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Section>

      <Section className={classes.integrationSection} gutterVariant='padding'>
        <div className={classes.integrationColumnLeft}>
          <SectionTitleBlock
            title='Seamless Integration'
            description={
              "CycurID's services are designed specifically for flexibility and ease-of-use across expansive and diverse industries. Our Merchant Dashboard is simple and intuitive, and enables you to customize the specific tasks CycurID will do for your business."
            }
            darkTheme='True'
          />
          <FloatIconFeature
            title='Custom Verification Rules'
            description="Set what level of verification potential customers need to access your platform, whether it's as simple as an email address, or as secure as a piece of government-issued ID."
            darkTheme='True'
          />
          <FloatIconFeature
            title='Tailored Onboarding Criteria'
            description='Keep under-age gamers or geo-blocked users from compromising your Terms of Service. CycurID pre-screens potential users at the point-of-access to your platform.'
            darkTheme='True'
          />
        </div>
        <StaticImage
          src={IntegrationImg}
          alt='CycurID dashboard onboarding tools for location, age, and verification level'
          className={classes.integrationImage}
          placeholder='blurred'
          quality={100}
        />
      </Section>

      <Section className={classes.advantagesSection}>
        <div className={classes.advantagesGrid}>
          {advantagesItems.map((item) => (
            <div className={classes.advantagesGridItem} key={item.title}>
              {item.icon}
              <Typography variant='h3'>{item.title}</Typography>
              <Typography variant='body2' style={{ color: '#737373' }}>
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </Section>

      <CTABox
        title='Be ready to do business as soon as your customers are.'
        description={`Customers expect quick and easy interactions. This means that manual processes that have a considerable impact on turnaround times drive away customers.${'\n\n'}Customer onboarding is fundamental to every company’s success and growth. Contact us to find out how to put CycurID to work for you.`}
        className={classes.ctaBox}
        bar='true'
      />
      <ContactUs />
    </main>
  )
}
